import React from 'react';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><a href="/#about">About</a></li>
            <li><a href="/pricing/">Pricing</a></li>
            <li><a href="/builder/trading-strategy/">Scripts</a></li>
            <li><a href="https://use.autoview.com/" target="_blank" rel="noreferrer">Guides</a></li>
            <li><a href="/syntax/">Alert Syntax</a></li>
            <li class="d-block d-lg-none"><a href="/platforms/">Get Autoview &gt;</a></li>
            <li class="d-block d-lg-none"><a href="/login/">Login</a></li>
            <li class="d-block d-lg-none"><a href="/register/">Sign up</a></li>
        </ul>
    )
}

export default Nav;
